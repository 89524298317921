<template>
  <li class="folder" :class="search.length === 0 && currentFolder === folder ? 'active' : ''">
    <div class="label clearfix" @click="changeFolder(folder)" @dblclick="showChildren = !showChildren" v-show="!editing" @contextmenu="openMenu">
      <i class="fa pull-right pt-1 pl-4 pr-4" v-if="folder.children.length > 0" :class="showChildren ? 'fa-angle-up' : 'fa-angle-down'" @click="showChildren = !showChildren"></i>
      {{ folder.name }}
    </div>
    <div class="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" @blur="closeMenu" :style="{top: top, left: left}">
      <div @click="add">Nouveau dossier</div>
      <div @click="startEditing" v-if="this.folder.id !== null">Renommer</div>
      <!--            <div @click="remove" v-if="this.folder.id !== null">Supprimer</div>-->
    </div>
    <b-form-input size="sm" v-model="folder.name" v-show="editing" @keypress.enter.prevent="doneEditing" @keypress.esc.prevent="cancelEditing" @blur="doneEditing" v-focus="editing" @focus.native="$event.target.select()"></b-form-input>
    <ul v-show="showChildren">
      <folder v-for="(child, key) in folder.children" :key="key" :folder="child" :depth="depth + 1" :currentFolder="currentFolder" :search="search" @changeFolder="changeFolder" @deleteNew="deleteNew" @removeChild="removeChild" @openChildren="openChildren"></folder>
    </ul>
  </li>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'Folder',
  props: ['folder', 'depth', 'currentFolder', 'search'],
  data () {
    return {
      viewMenu: false,
      top: '0px',
      left: '0px',
      editing: this.folder.id === 0,
      oldName: '',
      showChildren: this.depth < 1,
    }
  },
  created () {
    if (this.folder === this.currentFolder) {
      this.$emit('openChildren')
    }
  },
  methods: {
    openMenu: function (e) {
      this.viewMenu = true

      Vue.nextTick(function () {
        this.$refs['right'].focus()

        this.setMenu(e.y, e.x)
      }.bind(this))

      e.preventDefault()
    },
    setMenu: function (top, left) {
      let largestHeight = window.innerHeight - this.$refs['right'].offsetHeight - 25
      let largestWidth = window.innerWidth - this.$refs['right'].offsetWidth - 25

      if (top > largestHeight)
        top = largestHeight

      if (left > largestWidth)
        left = largestWidth

      this.top = top + 'px'
      this.left = left + 'px'
    },
    closeMenu: function () {
      this.viewMenu = false
    },
    changeFolder (folder) {
      this.$emit('changeFolder', folder)
    },
    startEditing () {
      this.editing = true
      this.oldName = this.folder.name
    },
    cancelEditing () {
      if (!this.editing) {
        //Le done est déjà passé avant
        return false
      }

      this.editing = false

      //Si c'est un nouveau dossier, on le supprime, sinon on annule la saisie
      if (this.folder.id === 0) {
        this.$emit('deleteNew')
      } else {
        this.folder.name = this.oldName
      }
    },
    doneEditing () {
      if (!this.editing) {
        //Le blur est déjà passé avant
        return false
      }

      this.editing = false

      if (this.folder.id === 0) {
        const formData = new FormData()
        formData.append('parent', this.folder.parent)
        formData.append('name', this.folder.name)

        axios.post('/ipocam/finder/folder/add', formData).then((response) => {
          this.folder.id = response.data.id
          this.changeFolder(this.folder)
        })
      } else {
        const formData = new FormData()
        formData.append('folder', this.folder.id)
        formData.append('name', this.folder.name)

        axios.post('/ipocam/finder/folder/rename', formData)
      }
    },
    add () {
      let newFolder = { id: 0, name: 'Nouveau dossier', parent: this.folder.id, children: [] }

      this.showChildren = true
      this.folder.children.push(newFolder)
    },
    deleteNew () {
      this.folder.children = this.folder.children.filter(child => child.id !== 0)
    },
    remove () {
      this.closeMenu()

      this.$bvModal.msgBoxConfirm('Êtes-vous sûr ? La suppression entrainera la perte des fichiers de ce dossier', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
      }).then(value => {
        if (value) {
          this.$emit('removeChild', this.folder)
        }
      })
    },
    removeChild (folder) {
      this.folder.children = this.folder.children.filter(child => child !== folder)

      if (folder === this.currentFolder) {
        this.changeFolder(this.folder)
      }
    },
    openChildren () {
      this.showChildren = true
      this.$emit('openChildren')
    }
  },
  directives: {
    focus (el, value) {
      if (value) {
        Vue.nextTick(_ => {
          el.focus()
        })
      }
    }
  }
}
</script>

<style scoped>
.folder {
  list-style-type: none;
}

.folder > .label {
  cursor: pointer;
  padding: 0.25rem 0.75rem;
}

.folder > .label:hover {
  background: #3a4248;
}

.folder.active > .label {
  color: #20a8d8;
  background: #3a4248;
}
</style>
