.required:after {
  content: ' *';
  color: #f86c6b;
}

.form-group {
  margin-bottom: 0.5rem;
}

div.radio-image div.radio-image-item.selected {
  border: 2px solid #607D8B;
  background: #efefef;
  filter: alpha(opacity=100);
  opacity: 1;
}

div.radio-image-item {
  cursor: pointer;
}

.dropzone-file-area {
  min-height: 10rem;
}

.dropzone-file-area div.dz-default.dz-message {
  min-height: 10rem;
  height: 10rem;
}

.dropzone-file-area .dz-default.dz-message:before {
  top: 1rem;
}

.dropzone-file-area .dz-default.dz-message > span {
  margin-top: 5.25rem;
}

.dropzone-file-area .dz-preview, .dropzone-previews .dz-preview {
  width: 5rem;
  min-width: 6rem;
}
