@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-LightItalic.eot');
    src: url('./Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-LightItalic.woff2') format('woff2'),
    url('./Roboto-LightItalic.woff') format('woff'),
    url('./Roboto-LightItalic.ttf') format('truetype'),
    url('./Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium.eot');
    src: url('./Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Medium.woff2') format('woff2'),
    url('./Roboto-Medium.woff') format('woff'),
    url('./Roboto-Medium.ttf') format('truetype'),
    url('./Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BlackItalic.eot');
    src: url('./Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-BlackItalic.woff2') format('woff2'),
    url('./Roboto-BlackItalic.woff') format('woff'),
    url('./Roboto-BlackItalic.ttf') format('truetype'),
    url('./Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.eot');
    src: url('./Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Bold.woff2') format('woff2'),
    url('./Roboto-Bold.woff') format('woff'),
    url('./Roboto-Bold.ttf') format('truetype'),
    url('./Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-ThinItalic.eot');
    src: url('./Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-ThinItalic.woff2') format('woff2'),
    url('./Roboto-ThinItalic.woff') format('woff'),
    url('./Roboto-ThinItalic.ttf') format('truetype'),
    url('./Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Thin.eot');
    src: url('./Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Thin.woff2') format('woff2'),
    url('./Roboto-Thin.woff') format('woff'),
    url('./Roboto-Thin.ttf') format('truetype'),
    url('./Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Black.eot');
    src: url('./Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Black.woff2') format('woff2'),
    url('./Roboto-Black.woff') format('woff'),
    url('./Roboto-Black.ttf') format('truetype'),
    url('./Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BoldItalic.eot');
    src: url('./Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-BoldItalic.woff2') format('woff2'),
    url('./Roboto-BoldItalic.woff') format('woff'),
    url('./Roboto-BoldItalic.ttf') format('truetype'),
    url('./Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Light.eot');
    src: url('./Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Light.woff2') format('woff2'),
    url('./Roboto-Light.woff') format('woff'),
    url('./Roboto-Light.ttf') format('truetype'),
    url('./Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.eot');
    src: url('./Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Regular.woff2') format('woff2'),
    url('./Roboto-Regular.woff') format('woff'),
    url('./Roboto-Regular.ttf') format('truetype'),
    url('./Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-MediumItalic.eot');
    src: url('./Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-MediumItalic.woff2') format('woff2'),
    url('./Roboto-MediumItalic.woff') format('woff'),
    url('./Roboto-MediumItalic.ttf') format('truetype'),
    url('./Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Italic.eot');
    src: url('./Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('./Roboto-Italic.woff2') format('woff2'),
    url('./Roboto-Italic.woff') format('woff'),
    url('./Roboto-Italic.ttf') format('truetype'),
    url('./Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poiret One';
    src: url('./PoiretOne-Regular.eot');
    src: url('./PoiretOne-Regular.eot?#iefix') format('embedded-opentype'),
    url('./PoiretOne-Regular.woff2') format('woff2'),
    url('./PoiretOne-Regular.woff') format('woff'),
    url('./PoiretOne-Regular.ttf') format('truetype'),
    url('./PoiretOne-Regular.svg#PoiretOne-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('./GillSans.eot');
    src: url('./GillSans.eot?#iefix') format('embedded-opentype'),
    url('./GillSans.woff2') format('woff2'),
    url('./GillSans.woff') format('woff'),
    url('./GillSans.ttf') format('truetype'),
    url('./GillSans.svg#GillSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bellerose';
    src: url('./BelleroseLight.eot');
    src: url('./BelleroseLight.eot?#iefix') format('embedded-opentype'),
    url('./BelleroseLight.woff2') format('woff2'),
    url('./BelleroseLight.woff') format('woff'),
    url('./BelleroseLight.ttf') format('truetype'),
    url('./BelleroseLight.svg#BelleroseLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Alex Brush';
    src: url('./AlexBrush-Regular.eot');
    src: url('./AlexBrush-Regular.eot?#iefix') format('embedded-opentype'),
    url('./AlexBrush-Regular.woff2') format('woff2'),
    url('./AlexBrush-Regular.woff') format('woff'),
    url('./AlexBrush-Regular.ttf') format('truetype'),
    url('./AlexBrush-Regular.svg#AlexBrush-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Times';
    src: url('./Times-Roman.eot');
    src: url('./Times-Roman.eot?#iefix') format('embedded-opentype'),
    url('./Times-Roman.woff2') format('woff2'),
    url('./Times-Roman.woff') format('woff'),
    url('./Times-Roman.ttf') format('truetype'),
    url('./Times-Roman.svg#Times-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mona Lisa Solid';
    src: url('./MonaLisaSolidITC-Normal.eot');
    src: url('./MonaLisaSolidITC-Normal.eot?#iefix') format('embedded-opentype'),
    url('./MonaLisaSolidITC-Normal.woff2') format('woff2'),
    url('./MonaLisaSolidITC-Normal.woff') format('woff'),
    url('./MonaLisaSolidITC-Normal.ttf') format('truetype'),
    url('./MonaLisaSolidITC-Normal.svg#MonaLisaSolidITC-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Archivo Narrow';
    src: url('./ArchivoNarrow-Regular.eot');
    src: url('./ArchivoNarrow-Regular.eot?#iefix') format('embedded-opentype'),
    url('./ArchivoNarrow-Regular.woff2') format('woff2'),
    url('./ArchivoNarrow-Regular.woff') format('woff'),
    url('./ArchivoNarrow-Regular.ttf') format('truetype'),
    url('./ArchivoNarrow-Regular.svg#ArchivoNarrow-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Indie Flower';
    src: url('./IndieFlower.eot');
    src: url('./IndieFlower.eot?#iefix') format('embedded-opentype'),
    url('./IndieFlower.woff2') format('woff2'),
    url('./IndieFlower.woff') format('woff'),
    url('./IndieFlower.ttf') format('truetype'),
    url('./IndieFlower.svg#IndieFlower') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('./JosefinSans-Regular.eot');
    src: url('./JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./JosefinSans-Regular.woff2') format('woff2'),
    url('./JosefinSans-Regular.woff') format('woff'),
    url('./JosefinSans-Regular.ttf') format('truetype'),
    url('./JosefinSans-Regular.svg#JosefinSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
