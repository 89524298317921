.table .btn {
  border-radius: $btn-border-radius-sm;
  line-height: 1;
}

.btn-light {
  color: #23282c;
  background-color: #fff;
  border-color: #ddd;

  &:hover {
    background-color: #fbfbfb;
  }
}

.btn-info {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

/* btn-xs */
.btn-xs, .btn-group-xs > .btn {
  padding: 0.2125rem 0.55rem;
  font-size: 0.65rem;
  line-height: 1.6667;
  border-radius: 0.125rem;
}

.btn-xs + .dropdown-toggle-split, .btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: 0.275rem;
  padding-left: 0.275rem;
}

.btn-primary {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:disabled {
    color: #fff;
  }

  &.disabled {
    color: #fff;
  }
}