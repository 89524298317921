.table-xs {
  th,
  td {
    padding: $table-cell-padding-xs;
  }
}

.table th, .table td {
  vertical-align: middle;
}

.dataTables_length {
  display: none !important;
}

.dataTables_wrapper.dt-bootstrap4 .custom-select {
  background: none;
}

@media (min-width: 1200px) {
  .dataTables_length {
    display: inline-block !important;
  }
}

@media (max-width: 827px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}