<template>
  <div class="file" v-show="show">
    <b-img fluid thumbnail :src="file.thumbnail" :alt="shortname" @click="$emit('selectFile', file)" @contextmenu="openMenu"></b-img>
    <span class="name">{{ shortname }}</span>
    <div class="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" @blur="closeMenu" :style="{top: top, left: left}">
      <div @click="showInfos">Informations</div>
      <div @click="copyClipboard">Copier l'URL</div>
      <!--            <div @click="remove">Supprimer</div>-->
    </div>
    <b-modal ref="modal-infos" size="lg" hide-header hide-footer>
      <b-row>
        <b-col cols="6" class="text-center">
          <b-img fluid rounded :src="file.src" :alt="shortname"></b-img>
        </b-col>
        <b-col>
          <table class="table">
            <tr>
              <th class="text-right">Nom</th>
              <td class="editable clearfix" @click="startEditing">
                <i class="fa fa-pencil pull-right ml-2 text-muted pt-1" v-show="!editing"></i>
                <span v-show="!editing">{{ file.name }}</span>
                <b-form-input v-model="file.name" v-show="editing" @keypress.esc.stop="cancelEditing" @keypress.enter.stop="doneEditing" @blur="doneEditing" v-focus="editing"></b-form-input>
              </td>
            </tr>
            <tr>
              <th class="text-right">Format</th>
              <td>
                {{ file.extension }}
              </td>
            </tr>
            <tr>
              <th class="text-right">Poids</th>
              <td>
                {{ file.fileSize }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import axios from 'axios'

Vue.use(VueClipboard)

export default {
  name: 'File',
  props: ['file', 'currentFolder', 'search'],
  data () {
    return {
      viewMenu: false,
      top: '0px',
      left: '0px',
      editing: false,
      oldName: '',
    }
  },
  computed: {
    show: function () {
      let show = true

      if (this.search.length > 0) {
        this.search.forEach((term) => {
          if (!this.file.name.match(new RegExp(term, 'i'))) {
            show = false
          }
        })
      } else if (this.file.folder !== this.currentFolder.id) {
        show = false
      }

      return show
    },
    shortname: function () {
      return this.file.name.length > 15 ? this.file.name.slice(0, 15) + '..' : this.file.name
    }
  },
  methods: {
    openMenu: function (e) {
      this.viewMenu = true

      Vue.nextTick(function () {
        this.$refs['right'].focus()

        this.setMenu(e.y, e.x)
      }.bind(this))

      e.preventDefault()
    },
    setMenu: function (top, left) {
      let largestHeight = window.innerHeight - this.$refs['right'].offsetHeight - 25
      let largestWidth = window.innerWidth - this.$refs['right'].offsetWidth - 25

      if (top > largestHeight)
        top = largestHeight

      if (left > largestWidth)
        left = largestWidth

      this.top = top + 'px'
      this.left = left + 'px'
    },
    closeMenu: function () {
      this.viewMenu = false
    },
    showInfos () {
      this.$refs['modal-infos'].show()
    },
    remove () {
      this.closeMenu()

      this.$bvModal.msgBoxConfirm('Êtes-vous sûr ?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
      }).then(value => {
        if (value) {
          this.$emit('removeFile', this.file)
        }
      })
    },
    startEditing () {
      this.editing = true
      this.oldName = this.file.name
    },
    cancelEditing () {
      if (!this.editing) {
        //Le done est déjà passé avant
        return false
      }

      this.editing = false

      this.file.name = this.oldName
    },
    doneEditing () {
      if (!this.editing) {
        //Le blur est déjà passé avant
        return false
      }

      this.editing = false

      const formData = new FormData()
      formData.append('file', this.file.id)
      formData.append('name', this.file.name)

      axios.post('/ipocam/finder/file/rename', formData)
    },
    copyClipboard () {
      this.$copyText(window.location.origin + this.file.src)
    }
  },
  directives: {
    focus (el, value) {
      if (value) {
        Vue.nextTick(_ => {
          el.focus()
        })
      }
    }
  }
}
</script>

<style scoped>
.file {
  display: inline-block;
  width: 100px;
  margin: 5px 10px;
  overflow: hidden;
  text-align: center;
}

.file > img {
  cursor: pointer;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.file .name {
  font-size: 0.65rem !important;
  color: #495057;
}

.editable {
  cursor: pointer;
}
</style>
