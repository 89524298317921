$primary: #ffa726;
$warning: #ff6334;

$font-size-base: .8rem;

$border-radius: .1875rem;
$border-radius-lg: .25rem;
$border-radius-sm: .15rem;

$spacer: .8rem !default;

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;

$table-cell-padding: .5rem;
$table-cell-padding-sm: .25rem;
$table-cell-padding-xs: 0.2rem;

$card-spacer-y: .75rem;
$card-spacer-x: 1rem;

$modal-inner-padding: .8rem;
$modal-header-padding-y: .5rem;
$modal-header-padding-x: .5rem;

$alert-padding-y: .5rem;
$alert-padding-x: 1rem;

$list-group-item-padding-y: .5rem;
$list-group-item-padding-x: 1rem;

$sidebar-nav-title-padding-y: .5rem;
$sidebar-nav-title-padding-x: .75rem;
$sidebar-nav-link-padding-y: .25rem;
$sidebar-nav-link-padding-x: .75rem;
$sidebar-nav-link-active-color: #20a8d8;

$breadcrumb-padding-y: .5rem;
$breadcrumb-margin-bottom: .75rem;

$badge-padding-y: .4em;
$badge-padding-x: .4em;
