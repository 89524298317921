@import "variables";
@import "~@coreui/coreui/scss/coreui";
@import "buttons";
@import "forms";
@import "layouts";
@import "tables";
@import "../plugins/font/font.css";

body, html {
  height: 100%;
}

div.popup {
  z-index: 1040 !important;
}

a:hover {
  text-decoration: none;
}

.font-xs {
  font-size: .7rem !important;
}

.font-sm {
  font-size: .75rem !important;
}

.font-lg {
  font-size: 0.9rem !important;
}

.font-xl {
  font-size: 1rem !important;
}

.font-2xl {
  font-size: 1.25rem !important;
}

.font-3xl {
  font-size: 1.50rem !important;
}

.font-4xl {
  font-size: 1.75rem !important;
}

.font-5xl {
  font-size: 2rem !important;
}

.border-2 {
  border: 2px solid;
}

.border-3 {
  border: 3px solid;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-header .close {
  color: inherit;
}

.text-poiret {
  font-family: 'Poiret One';
}

@media (min-width: 992px) {
  .col-md-offset-3 {
    margin-left: 25%;
  }
}

.header-elements-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  .header-elements {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.card-header {
  padding: .5rem $card-spacer-x;

  .card-title {
    margin-bottom: 0;
  }
}

.badge-help {
  background-color: #fff;
  color: #00838F;
  display: inline-block;
  border: 2px solid #00838F;
  line-height: 2.125rem;
  text-align: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  font-size: 1.4rem;
}

.text-help {
  color: #00838F;
  font-weight: bold;
  font-size: .7rem;
}

.opacity-30 {
  opacity: 0.3;
}

.alert p {
  margin: 0;
}

/* Chat layouts */
.media-chat {
  max-height: 520px;
  overflow: auto;
  list-style-type: none;
  padding: 0.2rem;
  margin: 0;

  .media {
    @media (min-width: 576px) {
      margin-right: 20%;
      margin-top: .5rem;
    }
  }

  .media-chat-item {
    position: relative;
    padding: .5rem;
    width: auto;
    display: inline-block;
    color: #333;
    border-radius: .5rem;
    background-color: #eee;
    border-color: #eee;
  }

  .media.media-reverse {
    @media (min-width: 576px) {
      margin-right: 0;
      margin-left: 20%;
    }

    .media-body {
      text-align: right;
    }

    .media-chat-item {
      text-align: left;
      background: #2f84ff;
      color: #fff;
    }
  }

  .comment-changes_requested {
    color: #853a23;
  }

  .comment-customer_validation {
    color: #285b2a;
  }

  .comment-info {
    color: #336573;
  }

  .comment-printing-asked {
    color: #aa0624;
  }
}

#documents-container .dz-file-preview {
  display: none;
}

.bloc-step {
  line-height: 1.2;
}

.btn-warning {
  color: #fff;
}

.card-img-actions {
  position: relative;
}

.card-img-actions-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.15s;
}

.card-img-actions:hover .card-img-actions-overlay, .card-img-actions:focus .card-img-actions-overlay {
  opacity: 1;
  visibility: visible;
}

.chart-container {
  width: 100%;
  min-height: 50vh;
}

.related-docs {
  .list-group-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .file-icon {
      width: 20px;
    }
  }
}

body.login-container {
  background: #f5f5f5 url(./bg-login.jpg) no-repeat 50% fixed;
  background-size: cover;
}

#tutorials {
  .card, .card i {
    transition: .2s ease;
  }

  .card:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px 1px grey;
  }

  .card:hover i {
    color: #2587df;
  }
}

.notification-box {
  overflow: hidden;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus {
  color: #ffffff;
  background-color: #ffa726;
  border-color: #c8ced3;
  border-bottom-color: #fff;
  font-weight: bold;
}

.nav-tabs .nav-link {
  color: #73818f;
  background-color: rgba(255, 255, 255, .5);
  border: 1px solid #c8ced3;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;

  &.low {
    background-color: #2ecc71;
  }

  &.medium {
    background-color: #f39c12;
  }

  &.high {
    background-color: #c0392b;
  }
}
