<template>
  <div>
    <div class="finder-actions">
      <b-img thumbnail :src="currentFile ? currentFile.thumbnail : ''" :alt="currentFile ? currentFile.name : ''" @click="openFinder" v-if="currentFile !== null"></b-img>
      <button type="button" class="btn btn-light btn-sm w-100" @click.prevent="openFinder" v-if="currentFile === null">
        <i class="fa fa-folder-open-o"></i>&nbsp;Parcourir
      </button>
      <button type="button" class="btn btn-danger btn-sm w-100" @click.prevent="deselectFile" v-if="currentFile !== null">
        <i class="fa fa-trash"></i>&nbsp;Supprimer
      </button>
    </div>

    <div class="finder-popup" v-if="opened">
      <div class="finder-bg" @click="closeFinder"></div>
      <div class="finder-inner">
        <b-row>
          <b-col cols="3" class="finder-sidebar">
            <div class="content">
              <ul>
                <folder :folder="folders" :depth="0" :currentFolder="currentFolder" :search="search" @changeFolder="changeFolder"/>
              </ul>
            </div>
          </b-col>
          <b-col class="finder-main">
            <div class="m-3 clearfix">
              <button type="button" class="btn finder-upload btn-sm pull-left mr-3"><i class="fa fa-upload"></i>&nbsp;Envoyer un fichier</button>

              <div class="finder-search pull-left clearfix">
                <i class="fa fa-times-circle pull-right pt-2 clear" @click.prevent="clearSearch" v-if="search.length > 0"></i>
                <div class="form-inline">
                  <i class="fa fa-search"></i>
                  <b-form-input size="sm" v-model="terms" @keyup.prevent="makeSearch" placeholder="Rechercher" @keypress.enter.prevent=""></b-form-input>
                </div>
              </div>

              <div class="pull-right text-muted pt-2" v-show="search.length === 0">
                {{ nbFilesCurrent }} fichier{{ nbFilesCurrent > 1 ? 's' : '' }}
              </div>
            </div>

            <vue2-dropzone :ref="'dropzone-' + finderId" :id="'dropzone-' + finderId" class="content" :options="dropzoneOptions" :useCustomSlot="true" @vdropzone-sending="dropzoneSending" @vdropzone-success="dropzoneSuccess" @vdropzone-complete="dropzoneComplete" @vdropzone-error="dropzoneError" @vdropzone-total-upload-progress="dropzoneProgress">
              <b-progress :value="progress" :max="100" animated v-if="progress > 0 && progress < 100"></b-progress>
              <file v-for="(file, key) in files" :key="key" :file="file" :currentFolder="currentFolder" :search="search" @removeFile="removeFile" @selectFile="selectFile"/>
              &nbsp;
            </vue2-dropzone>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Folder from './Folder'
import File from './File'
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'Finder',
  components: { File, Folder, vue2Dropzone },
  data () {
    return {
      opened: false,
      folders: {
        id: null,
        name: '/',
        parent: null,
        children: []
      },
      currentFolder: null,
      files: [],
      currentFile: null,
      terms: '',
      search: [],
      dropzoneOptions: {
        url: '/ipocam/finder/file/add',
        maxFiles: null,
        clickable: '.finder-upload',
        autoProcessQueue: true,
        acceptedFiles: 'image/*, .doc, .docx, .pdf, .indd, .psd, .xls, .xlsx',
        autoDiscover: false,
        dictDefaultMessage: 'Déposer des fichiers ici pour les envoyer',
        dictFallbackMessage: 'Votre navigateur ne supporte pas les téléchargements de fichiers drag\'n\'drop.',
        dictFallbackText: 'S\'il vous plaît utiliser le formulaire de remplacement ci-dessous pour envoyer vos fichiers.',
        dictFileTooBig: 'Le fichier est trop gros ({{filesize}} MiB). Max taille du fichier: {{maxFilesize}} MiB.',
        dictInvalidFileType: 'Vous ne pouvez pas envoyer de fichiers de ce type.',
        dictResponseError: 'Le serveur a répondu avec le code {{statusCode}}.',
        dictCancelUpload: 'Annuler l\'envoi',
        dictCancelUploadConfirmation: 'Êtes-vous sûr de vouloir annuler cet envoi ?',
        dictRemoveFile: 'Effacer le fichier',
        dictMaxFilesExceeded: 'Vous ne pouvez pas envoyer plus de fichiers.',
      },
      progress: 0
    }
  },
  props: ['finderId'],
  async mounted () {
    await axios.get('/ipocam/finder/folder/list')
        .then(response => {
          this.folders.children = response.data

          const currentFolderId = localStorage.currentFolderId ? parseInt(localStorage.currentFolderId) : null

          const folder = findFolder(this.folders, currentFolderId)

          if (folder !== false) {
            this.currentFolder = folder
          } else {
            this.currentFolder = this.folders
          }
        })

    const fileId = document.getElementById('finder-value-' + this.finderId).value

    if (fileId) {
      await axios.get('/ipocam/finder/file/find/' + fileId)
          .then(response => {
            this.currentFile = response.data
            const folder = findFolder(this.folders, response.data.folder)

            if (folder !== false) {
              this.currentFolder = folder
            } else {
              this.currentFolder = this.folders
            }
          })
    }

    this.findFiles()
  },
  computed: {
    nbFilesCurrent () {
      let filesCurrent = this.files.filter(file => file.folder === this.currentFolder.id)

      return filesCurrent.length
    }
  },
  methods: {
    findFiles () {
      let route = '/ipocam/finder/folder/file/list'

      if (this.currentFolder !== null) {
        route += '/' + this.currentFolder.id
      }

      axios.get(route)
          .then(response => {
            this.files = response.data
          })
    },
    openFinder () {
      this.opened = true
    },
    closeFinder () {
      this.opened = false
    },
    changeFolder (folder) {
      this.currentFolder = folder

      localStorage.currentFolderId = this.currentFolder.id

      this.findFiles()

      this.terms = ''
      this.makeSearch()
    },
    removeFile (fileToDelete) {
      this.files = this.files.filter(file => file !== fileToDelete)
    },
    makeSearch () {
      let value = this.terms.trim()

      this.search = []

      if (value !== '') {
        this.search = value.split(/(\s+)/).filter((e) => {
          return e.trim().length > 0
        })
      }
    },
    clearSearch () {
      this.terms = ''
      this.makeSearch()
    },
    selectFile (file) {
      this.currentFile = file

      document.getElementById('finder-value-' + this.finderId).value = this.currentFile.id

      this.closeFinder()
    },
    deselectFile () {
      this.currentFile = null

      document.getElementById('finder-value-' + this.finderId).value = null
    },
    dropzoneSending (file, xhr, formData) {
      formData.append('folder', this.currentFolder.id)
    },
    dropzoneSuccess (file, response) {
      if (response.id !== undefined) {
        this.files.push(response)
      }
    },
    dropzoneComplete (response) {
      response.previewElement.remove()
    },
    dropzoneError (file, message, xhr) {
      this.$bvModal.msgBoxOk('Une erreur est survenue', {
        buttonSize: 'sm',
        okVariant: 'danger',
      })
    },
    dropzoneProgress (totaluploadprogress, totalBytes, totalBytesSent) {
      this.progress = parseInt(totaluploadprogress)
    }
  }
}

function findFolder (folder, needle) {
  let found = false

  if (folder.id === needle) {
    found = folder
  }

  folder.children.forEach((child) => {
    let foundChild = findFolder(child, needle)
    if (foundChild !== false) {
      found = foundChild
    }
  })

  return found
}
</script>

<style>
.dz-preview {
  display: none;
}
</style>

<style>
.finder-actions {
  width: 100px;
}

.finder-actions img {
  cursor: pointer;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.finder-actions button {
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.finder-popup {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 1040;
}

.finder-bg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: .9;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.finder-inner {
  position: fixed;
  bottom: 0;
  background-color: #f7f8f9;
  border: 1px solid #888;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  font-size: 0.8rem;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 1040 !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.finder-inner .form-control {
  font-size: 0.8rem !important;
}

.finder-sidebar {
  background: #2f353a;
  color: #e4e7ea;
}

.finder-sidebar .content {
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 580px;
}

.finder-sidebar .content ul {
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 0 0 0 10px;
}

.finder-main .content {
  height: 500px;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid transparent;
}

.finder-main .content.dz-drag-hover {
  background-color: rgba(11, 11, 11, 0.1);
  opacity: 0.5;
  border: 1px dotted #2f353a;
}

.btn.finder-upload {
  color: #23282c;
  background-color: #e4e7ea;
  border-color: #ddd;
}

.btn.finder-upload:hover {
  background-color: #fff;
}

.finder-search {
  background: #e4e7ea;
  padding: 0 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.finder-search input, .finder-search input:focus {
  width: 700px !important;
  background-color: transparent;
  outline: none;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.finder-search .clear {
  cursor: pointer;
}

.dz-preview {
  display: none;
}

.right-click-menu {
  background: rgba(247, 248, 249, .95);
  color: #2f353a;
  border: 1px solid #BDBDBD;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1040;
  cursor: default;
  font-size: .75rem;
  text-align: left;
}

.right-click-menu > div {
  margin: 0;
  padding: 3px 20px;
}

.right-click-menu > div:hover {
  background: #20a8d8;
  color: #FAFAFA;
}

.modal-content {
  background: #f7f8f9;
}

.modal-dialog, .modal-dialog .btn, .modal-dialog .form-control {
  font-size: .8rem;
}
</style>
