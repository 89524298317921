.app-header {
  .navbar-brand {
    width: 200px;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .navbar-nav {
    .nav-item {
      min-width: auto;
      margin-right: 1.5rem;
    }
  }

  .dropdown-menu {
    top: 34px;
    -webkit-box-shadow: 3px 3px 5px #ccc;
    -moz-box-shadow: 3px 3px 5px #ccc;
    box-shadow: 3px 3px 5px #ccc;

    .dropdown-item {
      background: #fff;
      color: #73818f;

      i {
        color: #73818f;
      }
    }

    .dropdown-item:hover, .dropdown-item:focus {
      background: #e4e7ea;
      text-decoration: none;
    }
  }
}

#widget-notifications {
  .notification-info {
    border-left: 2px solid #63c2de;
  }

  .notification-danger {
    border-left: 2px solid #f86c6b;
  }
}

#quick-search {
  width: 400px;
  background-color: transparent;
  border: solid transparent;
  outline: none;
  color: rgba(255, 255, 255, 0.75);
}

#quick-search-results {
  position: absolute;
  width: 400px;
  z-index: 999;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  top: 42px;
  height: 0;
  visibility: hidden;
  transition: ease height .3s;
}

@media (min-width: 1200px) {
  #quick-search, #quick-search-results {
    width: 500px;
  }
}

#quick-search-results.hasResults {
  height: auto;
  visibility: visible;
}

.sidebar {
  .nav-title {
    font-size: 105%;
  }

  .nav-link {
    &.active {
      color: $primary;
      background: #3a4248;

    }

    &:hover {
      color: #fff;
      background: $primary;
    }
  }
}

.badge-primary {
  color: #fff;
}

.badge-warning {
  color: #fff;
}

.tippy-tooltip {
  max-width: 600px !important;
  background-color: transparent!important;
}

.shepherd.tippy-popper[x-placement^="bottom"].shepherd-has-title .tippy-arrow{
  border-bottom-color: #2587df !important;
}
.shepherd.tippy-popper[x-placement^="top"].shepherd-has-title .tippy-arrow {
  border-top-color: #2587df !important;
}
.shepherd.tippy-popper[x-placement^="left"].shepherd-has-title .tippy-arrow {
  border-left-color: #2587df !important;
}
.shepherd.tippy-popper[x-placement^="right"].shepherd-has-title .tippy-arrow {
  border-right-color: #2587df !important;
}

.shepherd-element.shepherd-theme-custom {
  .shepherd-content {
    background-color: transparent!important;
    .shepherd-header {
      // background: #2f353a !important;
      // background-color: #fff;
      background-color: #2587df;
      padding-top: 2em;
      padding-bottom: 0;

      .shepherd-title {
        cursor: default;
        color: #fff;
        // color: #000;
        text-transform: uppercase;
        // font-family: 'Indie Flower';
        font-weight: bold;
        font-size: 1rem;
        max-width: 90%;
        display: inline-block;
      }

      .shepherd-cancel-icon {
        color: #fff;
      }
    }

    .shepherd-text {
      cursor: default;
      font-size: .8rem;
      padding: 1.4rem;
      background-color: #2587df;
      color: #fff;
    }

    .shepherd-footer {
      padding: 0 1.75em 1.75em;
      background-color: #2587df;
      .shepherd-button {
        // background: #2f353a;
        background-color: transparent;
        color: #fff;
        // color: #000;
        // border: 2px solid #2f353a;
        border: 2px solid #fff;
        -webkit-border-radius: 40px;
        border-radius: 40px;
        padding: 0.4rem 1rem;
        font-size: .8rem;
        // font-family: 'Indie Flower';
        font-weight: bold;
        text-transform: initial;

        &:hover {
          color: #2587df;
          background: #fff;
          border-color: #fff;
        }
      }

      // .shepherd-button
    }
  }
}

.tour-highlight-search {
  background-color: #fff;
  color: #2f353a;

  #quick-search {
    color: #2f353a;
  }
}


body.shepherd-active .shepherd-target.shepherd-enabled {
  position: relative;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  pointer-events:  auto;
  transition: all 0.3s ease;
}

body.shepherd-active .shepherd-step {
  pointer-events:  auto;
  z-index: 9999;
}

body.shepherd-active {
  pointer-events:  none !important;
}

